import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/david/dev/terpy-samples/catalyst-core/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SEO = makeShortcode("SEO");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEO title="Page 1" mdxType="SEO" />
    <h1 {...{
      "id": "page-1"
    }}>{`Page 1`}</h1>
    <p>{`Donec viverra neque quis tincidunt aliquam. Fusce bibendum libero vel ligula viverra posuere. Proin convallis libero eget orci cursus ultrices.`}</p>
    <h2 {...{
      "id": "a-list"
    }}>{`A List`}</h2>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Item 1:`}</strong>{` Some kind of important thing you know. This is really important stuff. Wow!`}</li>
      <li parentName="ol"><strong parentName="li">{`Item 2:`}</strong>{` But this is more important I think…really truly. Trust me.`}</li>
      <li parentName="ol"><strong parentName="li">{`Item 3:`}</strong>{` I’m sorry. I lied. This is actually important now. I don’t know why I didn’t tell you about this before. It was just so amazing.`}</li>
      <li parentName="ol"><strong parentName="li">{`Item 4:`}</strong>{` The last one. My creativity is gone.`}</li>
    </ol>
    <h2 {...{
      "id": "a-picture"
    }}>{`A Picture`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1440px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAADBf/EABYBAQEBAAAAAAAAAAAAAAAAAAMAAv/aAAwDAQACEAMQAAABYXBYou49m//EABoQAAIDAQEAAAAAAAAAAAAAAAECAxEiABL/2gAIAQEAAQUCumIqSN8+9F8LKV7/xAAWEQEBAQAAAAAAAAAAAAAAAAABEBH/2gAIAQMBAT8BDZ//xAAYEQEBAAMAAAAAAAAAAAAAAAABAAIRUf/aAAgBAgEBPwFyS09v/8QAGxAAAgIDAQAAAAAAAAAAAAAAAAERMQIiQWH/2gAIAQEABj8Cl4+TI5Wr6a0YqyujhdP/xAAbEAEBAQACAwAAAAAAAAAAAAABEQAxQSFRcf/aAAgBAQABPyGmMVj0ZEXqR1qnkPHzCEkJ3pceRQRrf//aAAwDAQACAAMAAAAQ4O//xAAYEQACAwAAAAAAAAAAAAAAAAAAAREhUf/aAAgBAwEBPxBNhxh//8QAGBEBAAMBAAAAAAAAAAAAAAAAAQARMaH/2gAIAQIBAT8QISoVa5P/xAAeEAEAAgEEAwAAAAAAAAAAAAABESEAMUFRYXGhsf/aAAgBAQABPxBchSVZ21MGSEzO8Ivq/WCaFXdw2cPIB4XGmCCqVh+4UdYCNOs//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/5e8f9172631db490aa646bc3c66c9ffe/a3012/image1.webp 360w", "/static/5e8f9172631db490aa646bc3c66c9ffe/bc514/image1.webp 720w", "/static/5e8f9172631db490aa646bc3c66c9ffe/f992d/image1.webp 1440w", "/static/5e8f9172631db490aa646bc3c66c9ffe/62ed8/image1.webp 1800w"],
            "sizes": "(max-width: 1440px) 100vw, 1440px",
            "type": "image/webp"
          }}></source>{`
        `}<source parentName="picture" {...{
            "srcSet": ["/static/5e8f9172631db490aa646bc3c66c9ffe/158ba/image1.jpg 360w", "/static/5e8f9172631db490aa646bc3c66c9ffe/80e3c/image1.jpg 720w", "/static/5e8f9172631db490aa646bc3c66c9ffe/644c5/image1.jpg 1440w", "/static/5e8f9172631db490aa646bc3c66c9ffe/d9c39/image1.jpg 1800w"],
            "sizes": "(max-width: 1440px) 100vw, 1440px",
            "type": "image/jpeg"
          }}></source>{`
        `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/5e8f9172631db490aa646bc3c66c9ffe/644c5/image1.jpg",
            "alt": "Beakers",
            "title": "Beakers",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
      `}</picture>{`
    `}</span></p>
    <h2 {...{
      "id": "now-a-long-title-and-an-unordered-list-for-your-viewing-pleasure"
    }}>{`Now a long title and an unordered list for your viewing pleasure`}</h2>
    <ul>
      <li parentName="ul">{`Everything is out of order here that is why they call it an unordered list, maybe it should have been called random list.`}</li>
      <li parentName="ul">{`More randomness and disorder.`}</li>
      <li parentName="ul">{`Now this is just straight up chaos. Chaos list to ensure that I have everything in a case of disorder or the end of times.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      